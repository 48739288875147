import React, { useState } from "react";
import { images } from "../../../constants";
import "./Community.css";
import {
  Navbar,
  InfoBar,
  Footer,
  GoBack,
  SectionToggle,
} from "../../../components";

const team = [
  {
    id: 1,
    role: "Board",
    name: "Brandon Francis Okoth",
    description_1: "Founder",
    description_2: "Managing nDirector",
    img: images.brandon,
    message: (
      <>
        Thank you for taking the time to learn about us and the work we do. Your
        support is crucial to ensure brighter futures for vulnerable children.
      </>
    ),
  },
  {
    id: 2,
    role: "Board",
    name: "Magnus Heide",
    description_1: "Founder",
    description_2: "Director",
    img: images.magnus,
    message: (
      <>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit
        quisquam, dolor explicabo eaque sapiente. Lorem ipsum dolor sit, amet
        consectetur adipisicing elit. Reiciendis quos explicabo ipsum magnam
        saepe a iste sequi officiis nobis doloribus delectus in ratione quidem
        qui accusamus amet minima, fuga fugiat?
      </>
    ),
  },
  {
    id: 3,
    role: "Team",
    name: "Tr. Polycarp",
    description_1: "Head Teacher",
    img: images.polycarp,
  },
  {
    id: 4,
    role: "Team",
    name: "Tr. Rennie",
    description_1: "Swahili & Arts Teacher",
    img: images.rennie,
  },
  {
    id: 5,
    role: "Team",
    name: "Tr. Justin",
    description_1: "Mathematics & Sc. Teacher",
    img: images.justin,
  },
];

const grade = [
  {
    id: 1,
    role: "Student",
    name: "Derrick Otoba",
    description_1: "Description",
    img: images.derrick,
    message: "",
    gender: "boy",
  },
  {
    id: 2,
    role: "Student",
    name: "Elisha Odumaa",
    description_1: "Description",
    img: images.elisha,
    message: "",
    gender: "boy",
  },
  {
    id: 3,
    role: "Student",
    name: "Fidel Castro",
    description_1: "Description",
    img: images.fidel,
    message: "",
    gender: "boy",
  },
  {
    id: 4,
    role: "Student",
    name: "Frank Onyare",
    description_1: "Description",
    img: images.frank,
    message: "",
    gender: "boy",
  },
  {
    id: 5,
    role: "Student",
    name: "Marcus Garvey",
    description_1: "Description",
    img: images.marcus,
    message: "",
    gender: "boy",
  },
  {
    id: 6,
    role: "Student",
    name: "Mary Gati",
    description_1: "Description",
    img: images.mary,
    message: "",
    gender: "girl",
  },
  {
    id: 7,
    role: "Student",
    name: "Maryann Atieno",
    description_1: "Description",
    img: images.maryann,
    message: "",
    gender: "girl",
  },
  {
    id: 8,
    role: "Student",
    name: "Mercy Mongina",
    description_1: "Description",
    img: images.mercyM,
    message: "",
    gender: "girl",
  },
  {
    id: 9,
    role: "Student",
    name: "Mercy Achieng",
    description_1: "Description",
    img: images.mercyA,
    message: "",
    gender: "girl",
  },
  {
    id: 10,
    role: "Student",
    name: "Myghan Amuga",
    description_1: "Description",
    img: images.myghan,
    message: "",
    gender: "boy",
  },
  {
    id: 11,
    role: "Student",
    name: "Nelly Akoth",
    description_1: "Description",
    img: images.nelly,
    message: "",
    gender: "girl",
  },
  {
    id: 12,
    role: "Student",
    name: "Nelson Atswenje",
    description_1: "Description",
    img: images.nelson,
    message: "",
    gender: "boy",
  },
  {
    id: 13,
    role: "Student",
    name: "Neville Omondi",
    description_1: "Description",
    img: images.nevillegrey,
    message: "",
    gender: "boy",
  },
  {
    id: 14,
    role: "Student",
    name: "Reagan Ochieng",
    description_1: "Description",
    img: images.reagan,
    message: "",
    gender: "boy",
  },
  {
    id: 15,
    role: "Student",
    name: "Rehema Gift",
    description_1: "Description",
    img: images.rehema,
    message: "",
    gender: "girl",
  },
  {
    id: 16,
    role: "Student",
    name: "Shaneez Ashley",
    description_1: "Description",
    img: images.shaneez,
    message: "",
    gender: "girl",
  },
  {
    id: 17,
    role: "Student",
    name: "Shantel Faith",
    description_1: "Description",
    img: images.shantel,
    message: "",
    gender: "girl",
  },
  {
    id: 18,
    role: "Student",
    name: "Sheila Kerubo",
    description_1: "Description",
    img: images.sheila,
    message: "",
    gender: "girl",
  },
  {
    id: 19,
    role: "Student",
    name: "Trevor Akhuya",
    description_1: "Description",
    img: images.trevor,
    message: "",
    gender: "boy",
  },
  {
    id: 20,
    role: "Student",
    name: "Valeria Tasha",
    description_1: "Description",
    img: images.valeria,
    message: "",
    gender: "girl",
  },
];

export const boardMembers = team.filter((member) => member.role === "Board");
export const teamMembers = team.filter((member) => member.role === "Team");
export const studentMembers = grade.filter(
  (member) => member.role === "Student"
);

const Community = () => {
  const [genderFilter, setGenderFilter] = useState("all");
  const boyCount = studentMembers.filter(
    (member) => member.gender === "boy"
  ).length;
  const girlCount = studentMembers.filter(
    (member) => member.gender === "girl"
  ).length;

  const renderMember = (member) => {
    const firstName = member.name.split(" ")[0].toLowerCase();
    if (genderFilter !== "all" && member.gender !== genderFilter) {
      return null; // Skip rendering if gender does not match the filter
    }

    return (
      <div className="column" id={firstName}>
        <div className="card">
          <img src={member.img} alt={member.name} style={{ width: "100%" }} />
          <div className="container">
            <h2>{member.name}</h2>
            {(member.role === "Board" || member.role === "Team") && (
              <p className="title">
                {member.description_1}
                <br />
                {member.description_2 && `${member.description_2}`}
              </p>
            )}
            {/* <p onClick={() => openPopup(member)}>
                  <a className="button-design form">Read message</a>
                </p> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="community" id="page-container">
      <InfoBar />
      <Navbar />
      <div className="bodyMargin left content">
        <GoBack />
        <h1>Community</h1>
        <p>
          We are a school of great minds, changing the way education has been
          perceived for years in Kibera and in Kenya. We are transforming the
          way children are made to feel about seeking and acquiring education.
          We are relieving some of the heavy duties parents must carry to have
          access to quality education for their children. We are championing for
          quality education for all by providing a free junior secondary school
          for students living in the slums of Kibera, coming from very difficult
          backgrounds and need education for their children who are committed
          and willing to have the best education for themselves, their families
          and the community at large.
        </p>
        <SectionToggle
          sections={[
            { id: "students", label: "Students" },
            { id: "staff", label: "Staff" },
            { id: "board", label: "Board" },
          ]}
        >
          {(activeSection) => (
            <>
              {activeSection === "students" && (
                <div className="article blueBackground">
                  <h2>Students</h2>
                  <div className="genderBtns">
                    <button
                      onClick={() => setGenderFilter("all")}
                      className={`genderBtns ${
                        genderFilter === "all" ? "active" : ""
                      }`}
                    >
                      All
                    </button>
                    <button
                      onClick={() => setGenderFilter("boy")}
                      className={`genderBtns ${
                        genderFilter === "boy" ? "active" : ""
                      }`}
                    >
                      Boys
                      {/* <span className="">({boyCount})</span> */}
                    </button>
                    <button
                      onClick={() => setGenderFilter("girl")}
                      className={`genderBtns ${
                        genderFilter === "girl" ? "active" : ""
                      }`}
                    >
                      Girls
                      {/* ({girlCount}) */}
                    </button>
                  </div>
                  <div className="flex-container">
                    <div className="row2 twenty">
                      {studentMembers.map((member) => renderMember(member))}
                    </div>
                  </div>
                  <br />
                </div>
              )}

              {activeSection === "staff" && (
                <div className="article blueBackground">
                  <h2>Staff</h2>
                  <div className="flex-container">
                    <div className="row2">
                      {teamMembers.map((member) => renderMember(member))}
                    </div>
                  </div>
                  <br />
                </div>
              )}

              {activeSection === "board" && (
                <div className="article blueBackground" id="board">
                  <h2>Board</h2>
                  <div className="flex-container">
                    <div className="row2">
                      {boardMembers.map((member) => renderMember(member))}
                    </div>
                  </div>
                  <br />
                </div>
              )}
            </>
          )}
        </SectionToggle>
      </div>
      <Footer />
    </div>
  );
};

export default Community;
